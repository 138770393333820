import React from "react"
import {Link, graphql} from "gatsby"

import Layout from "../components/layout"

import Seo from "../components/seo"

    
const indexHtml = ({data,location}) => {
    return(
        <div>
            <Layout>
            <Seo
              pagetitle="TOP"
              pagedesc="MAZINはデータ分析、システム開発、センサ/PLCなどからのデータ収集に強みを持つ企業です。"
              pagepath={location.pathname}
             />
                <div className="container-fluid top-page">
                  <div className="row">
                    <div className="top-view">
                      <div className="top-img bg-img_cover" />
                      <div className="h1_wrap">
                        <h1 className="kakko">強い工場を造る。<span /><span /><span /><span /></h1>
                      </div>{/*.h1_wrap*/}
                      <a href="#scroll" id="scroll-button">
                        <div className="scroll-down">
                          <div>SCROLL</div><div />
                        </div>
                      </a>
                    </div>{/*.top-view*/}
                    <div id="scroll" className="contents-links contents">
                      <div className="links-wrap">
                          <Link to={`/sessaku/`}>
                            <div className="link-wrap link-sessaku bg-img_cover black-filter">
                                <div className="cat-title">PRODUCT 1</div>
                                <div className="link-title"><div>切削強化</div></div>
                                <div className="link-lead">切削加工業向け<br />IoTパッケージ</div>
                                <div className="link-more">MORE<div className="bg-img" /></div>
                            </div>
                          </Link>
                          <Link to={`/kumikomi/`}>
                            <div className="link-wrap link-kumikomi bg-img_cover black-filter">
                                <div className="cat-title">PRODUCT 2</div>
                                <div className="link-title"><div>製品強化</div></div>
                                <div className="link-lead">装置メーカー様向け<br />AI組込ソリューション</div>
                                <div className="link-more">MORE<div className="bg-img" /></div>
                            </div>
                          </Link>
                      </div>{/*.links-wrap*/}
                      <div className="links-wrap">
                          <Link to={`/kensaku/`}>
                            <div className="link-wrap link-kensaku bg-img_cover black-filter">
                                <div className="cat-title">PRODUCT 3</div>
                                <div className="link-title"><div>工程強化</div></div>
                                <div className="link-lead">製造工程<br />IoT基盤</div>
                                <div className="link-more">MORE<div className="bg-img" /></div>
                            </div>
                          </Link>
                          <div className="link-x_wrap">
                            <div className="link-wrap link-x bg-img_cover black-filter">
                              <div className="cat-title">PRODUCT X</div>
                              <div className="link-title"><div>開発中</div></div>
                              <div className="link-lead">「強い工場を造る。」<br />ために現在開発中</div>
                            </div>
                          </div>
                      </div>{/*.links-wrap*/}
                    </div>{/*.contents-links*/}
                    <div className="contents-links contents">
                      <div className="links-wrap">
                          <Link to={`/about/`}>
                            <div className="link-wrap link-about bg-img_cover black-filter">
                                <div className="cat-title">ABOUT</div>
                                <div className="link-title"><div>会社概要</div></div>
                                <div className="link-lead">製造現場に特化し伴走する<br />テクノロジー企業</div>
                                <div className="link-more">MORE<div className="bg-img" /></div>
                            </div>
                          </Link>
                          <Link to={`/product/`}>
                            <div className="link-wrap link-product bg-img_cover black-filter">
                                <div className="cat-title">PRODUCT</div>
                                <div className="link-title"><div>製品一覧</div></div>
                                <div className="link-lead">製造現場と伴走し開発した<br />独自のAI/IoTシステム製品</div>
                                <div className="link-more">MORE<div className="bg-img" /></div>
                            </div>
                          </Link>
                      </div>{/*.links-wrap*/}
                    </div>{/*.contents-links*/}
                    <div className="contents-links contents">
                      <div className="link-blogs">
                        <div className="cat-title">BLOG</div>
                            <div className="link-blog">
        
                        {data.allMarkdownRemark.edges.map(({ node }, index) => (
                              <Link to={`/blog${node.fields.slug}`}>
                                <img src={node.frontmatter.thumbnail} alt={node.frontmatter.title} className="blog-thumbnail" />
                                <div className="blog-info">
                                  <div className="blog-date">{node.frontmatter.date}</div>
                                  <div className="blog-title">{node.frontmatter.title}</div>
                                </div>
                              </Link>
                        ))}
                            </div>{/*.blog-link*/}
                        <Link to={`/blog`}>
                          <div className="link-more">MORE<div className="bg-img" /></div>
                        </Link>
                      </div>
                    </div>{/*.contents-links*/}
                  </div>{/*.row*/}
                </div>{/*.container-fluid*/}

              </Layout>
        </div>
    )
}

export const query = graphql`
    query MyQuery {
      allMarkdownRemark(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            frontmatter {
              thumbnail
              title
              date
            }
            fields {
              slug
            }
          }
        }
      }
    }
`

export default indexHtml