import React from 'react'
import FooterParts from "../components/footer_parts"

const footerNormalHtml = () => (
	
	  <footer>
		<div className="footer-img bg-img_cover" />
        <FooterParts />
	  </footer>

)

export default footerNormalHtml;
