import React from 'react'

import Header from "../components/header"
import Footer from "../components/footer_normal"

import 'bootstrap/dist/css/bootstrap.min.css'
import "./layout.css"


const layoutHtml = ({children}) => (
	
	<div>
	  <Header />
	  <main id="main">
		
		{children}
	
	  </main>
	  <Footer />
	</div>

)

export default layoutHtml;